// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require("@rails/ujs").start();
require("@rails/activestorage").start();
require("channels");

require("bootstrap");
require("perfect-scrollbar");
require("@coreui/coreui");

require("bootstrap-datepicker");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

!(function(a) {
  a.fn.datepicker.dates.en = {
    days: ["日曜", "月曜", "火曜", "水曜", "木曜", "金曜", "土曜"],
    daysShort: ["日", "月", "火", "水", "木", "金", "土"],
    daysMin: ["日", "月", "火", "水", "木", "金", "土"],
    months: [
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    monthsShort: [
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    today: "今日",
    format: "yyyy-mm-dd",
    titleFormat: "yyyy年mm月",
    clear: "クリア"
  };
})(jQuery);

$(function() {
  $(document).on("ajax:complete", ".request_calender_update", function(event) {
    response = event.detail[0].response;
    $("#updated_calender").html(response);
  });
  $(".request_calender_update").on("submit", function(event) {
    event.preventDefault();
    var $form = $(this);
    $.ajax({
      url: $form.attr("action"),
      type: $form.attr("method"),
      data: $form.serialize(),
      success: function(response) {
        $("#updated_calender").html(response);
      }
    });
  });
  $("form.request_calender_update").submit();

  $(".request_inline_calender_update").submit(function(event) {
    event.preventDefault();
    var $form = $(this);
    $.ajax({
      url: $form.attr("action"),
      type: $form.attr("method"),
      data: $form.serialize(),
      success: function(response) {
        $($form.data("target")).html(response);
      }
    });
  });
  $(".request_inline_calender_update").submit();

  $(".btn-add_cart").on("click", function(e) {
    event.preventDefault();
    let href = $(this).attr("href");
    let arg = new Object();
    url = location.search.substring(1).split("&");

    for (i = 0; url[i]; i++) {
      var k = url[i].split("=");
      arg[k[0]] = k[1];
    }

    let start = arg.start;
    let end = arg.end;
    let adult = arg.adult;
    let child = arg.child;
    let infant = arg.infant;
    let baby = arg.baby;
    let search_type = arg.search_type;

    $.ajax({
      type: "PUT",
      url: "/carts/add_item",
      data: {
        cart_item: {
          hotel_id: $(this).data("hotel-id"),
          rate_plan_code: $(this).data("rate-plan-code"),
          room_type_code: $(this).data("room-type-code"),
          params: {
            start: start,
            end: end,
            adult: adult,
            child: child,
            infant: infant,
            baby: baby,
            search_type: search_type
          }
        }
      },
      success: function(msg) {
        window.location.href = href;
      }
    });
  });

  let dt = new Date();
  $("#start-date").datepicker("setStartDate", dt);
  $("#start-date").datepicker("update", getFormatDate(dt));
  $("#start").val(getFormatDate(dt));

  let start_dt = new Date();
  start_dt.setDate(start_dt.getDate() + 1);
  let end_dt = new Date();
  end_dt.setDate(end_dt.getDate() + 99);
  $("#end-date").datepicker("setStartDate", start_dt);
  $("#end-date").datepicker("setEndDate", end_dt);
  $("#end-date").datepicker("update", getFormatDate(start_dt));
  $("#end").val(getFormatDate(start_dt));

  $("#start-date").on("change", function(e) {
    start_dt = new Date(e.target.value);
    start_dt.setDate(start_dt.getDate() + 1);

    end_dt = new Date(e.target.value);
    end_dt.setDate(end_dt.getDate() + 99);

    $("#end-date").datepicker("setStartDate", start_dt);
    $("#end-date").datepicker("setEndDate", end_dt);

    $("#end").val(getFormatDate(start_dt));
  });
});

function getFormatDate(dateTime) {
  let month =
    dateTime.getMonth() + 1 >= 10
      ? dateTime.getMonth() + 1
      : "0" + (dateTime.getMonth() + 1);
  let date =
    dateTime.getDate() >= 10 ? dateTime.getDate() : "0" + dateTime.getDate();
  return dateTime.getFullYear() + "-" + month + "-" + date;
}
